<template>
  <div>
    <div
      class="medication-detail"
      v-if="MedicationDetail && Object.keys(MedicationDetail).length"
    >
      <div class="medication-detail__top">
        <div class="content">
          <div class="medication-detail__top-container">
            <div class="medication-detail__text">
              <div class="medication-detail__name-container">
                <div
                  class="medication-detail__name"
                  v-html="MedicationDetail.name"
                ></div>
                <div
                  class="medication-detail__ingredient"
                  v-html="MedicationDetail.substance"
                ></div>
              </div>
              <div class="medication-detail__icons">
                <div
                  class="medication-detail__icon"
                  :class="{ 'medication-detail__icon_disable': !cat.active }"
                  v-for="cat in filteredIcons"
                  :key="cat.id"
                >
                  <router-link
                    v-if="cat.active"
                    :to="{ name: 'NosologyDetail', params: { slug: cat.slug } }"
                    ><img :src="$root.host + cat.icon"
                  /></router-link>
                  <div v-else><img :src="$root.host + cat.icon" /></div>
                </div>
              </div>
            </div>
            <div class="medication-detail__image-container">
              <img
                class="medication-detail__image"
                :src="MedicationDetail.image"
              />
            </div>
          </div>
        </div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Препараты АЗ', link: { name: 'Medications' } },
          { name: MedicationDetail.name },
        ]"
      />
      <div class="content">
        <div class="medication-detail__row">
          <div class="medication-detail__list">
            <div class="material__tags">
              <div
                class="material__tag"
                v-for="tag in MedicationDetail.nosology"
                :key="tag.id"
                v-html="tag.title"
              ></div>
              <Favorite
                class="large ml-auto ml-xs-0"
                :watchLater="MedicationDetail.watch_later"
                :favorite="MedicationDetail.favorite"
                @watchLater="
                  watchLater(
                    MedicationDetail.watch_later ? 'DELETE' : 'POST',
                    MedicationDetail.slug,
                    null,
                    true
                  )
                "
                @favorite="
                  favorite(
                    MedicationDetail.favorite ? 'DELETE' : 'POST',
                    MedicationDetail.slug,
                    null,
                    true
                  )
                "
              />
            </div>
            <div class="materials">
              <FileBlock
                v-for="material in MedicationDetail.files"
                :key="material.id"
                :material="material"
                :kind="material.file ? 'file' : 'link'"
                @linkClick="linkClick(material.name)"
              />
            </div>
            <div v-if="!MedicationDetail.hide_content">
              <InfoDrop v-for="(item, ind) in blocks" :key="ind" :item="item" />
            </div>
          </div>
          <RightAside :items="asideItems" @calendarClick="calendarClick" />
        </div>
      </div>
    </div>
    <div v-else-if="MedicationDetail === 404">
      <Choch class="inPage" />
    </div>
    <div v-else-if="MedicationDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../components/pageComponents/InfoDrop.vue";
import FileBlock from "../components/pageComponents/FileBlock.vue";
import RightAside from "../components/pageComponents/RightAside.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import Favorite from "@/components/pageComponents/Favorite.vue";
export default {
  metaInfo() {
    if (this.MedicationDetail.name) {
      return { title: this.MedicationDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.MedicationDetail.name };
    }
  },
  name: "MedicationDetail",
  components: {
    Breadcrumbs,
    RightAside,
    InfoDrop,
    FileBlock,
    Choch,
    Error,
    Favorite,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["MedicationDetail"]),
    filteredIcons() {
      return this.MedicationDetail.nosology.filter((el) => el.icon);
    },
    asideItems() {
      return [
        {
          title: "Календарь мероприятий",
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2.jpg`,
          image_desktop: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-2_small.jpg`,
          event: "calendarClick",
          to: {
            name: "Events",
            query: {
              therapeutic_areas: this.MedicationDetail.nosology
                .map((el) => el.slug)
                .join(","),
            },
          },
        },
        // {
        //   title: "Библиотека",
        //   image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/cardio-img/aside-3.jpg`,
        //   theme: "white",
        //   to: "#",
        // },
      ];
    },
    blocks() {
      let obj = [];
      let fields = [
        {
          title: "О препарате",
          slug: "about",
        },
        {
          title: "Фармакологические свойства",
          slug: "properties",
        },
        {
          title: "Показание к применению",
          slug: "indication",
        },
        {
          title: "Противопоказания",
          slug: "contraindications",
        },
        {
          title: "Способ применения и дозы",
          slug: "application",
        },
        {
          title: "Побочное действие",
          slug: "side_effects",
        },
        {
          title: "Передозировка",
          slug: "overdose",
        },
        {
          title: "Взаимодействие с другими лекарственными средствами",
          slug: "interaction",
        },
        {
          title: "Особые указания",
          slug: "special",
        },
        {
          title:
            "Влияние на способность к управлению транспортными средствами и механизмами",
          slug: "drive",
        },
        {
          title: "Форма выпуска",
          slug: "release_form",
        },
      ];
      for (let field of fields) {
        if (this.MedicationDetail[field.slug]) {
          obj.push({
            title: field.title,
            content: this.MedicationDetail[field.slug],
          });
        }
      }
      return obj;
    },
  },
  methods: {
    ...mapActions([
      "fetchMedicationDetail",
      "fetchMedicationFavorite",
      "fetchMedicationWatchLater",
      "fetchMedicationHistory",
    ]),
    async watchLater(method, slug, filter, favoritePage) {
      await this.fetchMedicationWatchLater({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchMedicationDetail({
        slug: this.$route.params.medication,
        access: this.$route.query.access || null,
      });
    },
    async favorite(method, slug, filter, favoritePage) {
      await this.fetchMedicationFavorite({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchMedicationDetail({
        slug: this.$route.params.medication,
        access: this.$route.query.access || null,
      });
    },
    linkClick(title) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "instruction download", {
          "instruction download": {
            "medications page name": title,
            ...this.$root.ymFields,
          },
        });
      }
    },
    calendarClick(item) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "medication page events click", {
          "medication page events click": {
            "medications page name": vm.MedicationDetail.name,
            ...this.$root.ymFields,
          },
        });
      }
      vm.$router.push(item.to).catch(() => {});
    },
  },
  async mounted() {
    if (!this.$route.query.access) {
      await this.fetchMedicationHistory(this.$route.params.medication);
    }
  },
  watch: {
    MedicationDetail() {
      localStorage.setItem("medicationBlock", this.MedicationDetail.slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.materials {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.medication-detail {
  &__top {
    height: 400px;
    padding: 32px 0;
    background-color: #ebefee;

    @media screen and (max-width: 1220px) {
      height: auto;
    }
  }

  &__top-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  & .content {
    height: 100%;
  }

  &__text {
    padding: 32px 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1220px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__image-container {
    width: 588px;
    height: 322px;
    margin-left: auto;

    @media screen and (max-width: 1220px) {
      margin-left: 0;
      height: 262px;
      width: 100%;
      margin-top: 40px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__icons {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      margin-top: 0;
      margin-left: auto;
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &__icon {
    margin-right: 8px;
    transition: 0.3s;

    & img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      object-position: center;
      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &_disable {
      pointer-events: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__name {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #830051;
  }

  &__ingredient {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    color: #656969;
  }

  &__row {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }
}

.material {
  &__tags {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    // margin-top: 16px;
  }
}
</style>
